import './App.css';
import RoomPage from './Room';
import EgressHelper from './helper';

function App() {
  return (
    <div className="container">
      <RoomPage
        url={EgressHelper.getLiveKitURL()}
        token={EgressHelper.getAccessToken()}
      />
    </div>
  );
}

export default App;
